<template>
  <div class="mt-5">
    <v-row>
      <v-col v-for="counter in counters" :key="counter.title">
        <Counter :counter="counter" />
      </v-col>
    </v-row>
    <div class="mt-3">
      <v-data-table
        :loading="loadingUser"
        :headers="headers"
        :items="users"
        class="elevation-1"
      >
        <template v-slot:[`item.user`]="{ item }">
          <v-avatar class="mr-3" size="25">
            <img
              :src="
                item.image_url ||
                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
              "
              alt="avatar"
            />
          </v-avatar>
          {{ item.primary_email }}
        </template>
        <template v-slot:[`item.date_joined`]="{ item }">
          {{ formatDate(item.date_joined) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            class="ma-3"
            x-small
            :color="item.active ? '#4ce051' : '#e04c4c'"
            text-color="white"
          >
            {{ item.active ? "ACTIVE" : "INACTIVE" }}
          </v-chip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import Counter from "../components/Counter.vue";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  components: {
    Counter,
  },
  data() {
    return {
      loadingUser: false,
      users: [],
      counters: [
        {
          icon: "mdi-account-supervisor-outline",
          iconBackground: "#d7ecfd",
          iconColor: "#3ca3f5",
          title: "Total Users",
          count: "...",
        },
        {
          icon: "mdi-cash-multiple",
          iconBackground: "#d7ecfd",
          iconColor: "#3ca3f5",
          title: "Total Billings",
          count: "...",
        },
      ],
      headers: [
        { text: "User", value: "user" },
        { text: "Date Joined", value: "date_joined" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  mounted() {
    this.totalUsers();
    this.totalBills();
  },
  methods: {
    totalUsers() {
      this.loadingUser = true;
      firebase
        .firestore()
        .collection("users")
        .where("organization_id", "==", this.currentOrganization.id)
        .onSnapshot((snapshot) => {
          this.loadingUser = false;
          if (!snapshot.empty) {
            const users = snapshot.docs.map((doc) => doc.data());
            this.counters[0].count = users.length;
            this.users = users;
          }
        });
    },
    totalBills() {
      firebase
        .firestore()
        .collection("Billing")
        .where("org_id", "==", this.currentOrganization.id)
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            const bills = snapshot.docs.map((doc) => doc.data());
            this.counters[1].count = bills.length;
          }
        });
    },
    formatDate(firebaseTimestamp) {
      return format(firebaseTimestamp.toDate(), "do MMM; yyyy");
    },
  },
};
</script>
