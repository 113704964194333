var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('v-row',_vm._l((_vm.counters),function(counter){return _c('v-col',{key:counter.title},[_c('Counter',{attrs:{"counter":counter}})],1)}),1),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingUser,"headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"25"}},[_c('img',{attrs:{"src":item.image_url ||
              'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',"alt":"avatar"}})]),_vm._v(" "+_vm._s(item.primary_email)+" ")]}},{key:"item.date_joined",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date_joined))+" ")]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"ma-3",attrs:{"x-small":"","color":item.active ? '#4ce051' : '#e04c4c',"text-color":"white"}},[_vm._v(" "+_vm._s(item.active ? "ACTIVE" : "INACTIVE")+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }