<template>
  <v-card class="pa-6" elevation="0">
    <v-row no-gutters>
      <v-avatar :color="counter.iconBackground" class="mr-3">
        <v-icon dark :color="counter.iconColor">{{ counter.icon }}</v-icon>
      </v-avatar>
      <div>
        <div class="title">{{ counter.title }}</div>
        <span class="counter"> {{ counter.count }} </span>
      </div>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    counter: {
      type: Object,
      require: true,
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 300;
  font-size: 1.1rem !important;
}
.counter {
  font-weight: bold;
}
</style>
